.widget-testimonial {
  margin: 0 0 30px 0;

  .widget-testimonial-portrait {
    display: block;
    width: 100%;
    height: auto;
  }

  .widget-testimonial-text {
    padding: 15px;
    position: relative;
    background-color: white;

    &::before {
      position: absolute;
      top: 15px;
      left: 10px;
      content: "„";
      font-size: 70px;
      font-weight: bold;
      color: theme-color("secondary");
      line-height: 0;
    }

    .widget-testimonial-name {
      padding-left: 40px;
      margin: 0;
    }

    .widget-testimonial-function {
      margin: 0;
      padding-left: 40px;
    }

    .widget-testimonial-cite {
      font-style: italic;
      font-size: 16px;
      margin: 15px 0 0 0;
      text-align: center;
    }

  }

  @media (min-width: 768px) {
    .widget-testimonial-portrait {
      float: left;
      width: 150px;
    }

    .widget-testimonial-text {
      margin-left: 150px;
      padding-left: 50px;

      .widget-testimonial-name {
        padding: 0;
      }

      .widget-testimonial-function {
        padding: 0;
        font-style: italic;
        font-size: 14px;
      }

      .widget-testimonial-cite {
        text-align: left;
      }
    }
  }
}

.green-bg .widget-testimonial {
  .widget-testimonial-text {
    background-color: theme-color("primary");
    color: white;

    &::before {
      color: white;
    }
  }
}


.widget-study {
  margin: 0 0 30px 0;
  border: 2px solid theme-color("primary");
  padding: 15px;

  .widget-study-icon {
    color: theme-color("primary");
    margin-bottom: 15px;
  }

  .widget-study-title {
    margin: 0;
  }

  .widget-study-authors {
    font-weight: normal;
    font-size: 12px;
  }

  .widget-study-link {
    font-size: 12px;
  }
}

.green-bg .widget-study {
  border: 2px solid white;

  .widget-study-icon {
    color: white;
  }

  .widget-study-link {
    color: white;
  }
}


.widget-fact {
  margin: 0 0 30px 0;

  .widget-fact-wrapper {
    background-color: white;
    border: 1px solid theme-color("primary");

    .widget-fact-number {
      background-color: theme-color("primary");
      padding: 40px 0 15px 0;
      min-height: 130px;
      font-weight: normal;
      font-size: 20px; // Gap between digits and unit
      text-align: center;
      color: white;

      .widget-fact-number-digits {
        font-size: 65px;
        line-height: 1em;
        display: inline-block;
        font-weight: bold;
      }

      .widget-fact-number-unit {
        line-height: 1em;
        font-size: 18px;
        display: inline-block;
      }
    }

    .widget-fact-text {
      padding: 15px;
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
    }
  }

  .widget-fact-source {
    font-size: 12px;
  }

  @media (min-width: 768px) {
    .widget-fact-wrapper {
      .widget-fact-number-wrapper {
        display: inline-block;
        float: left;
        margin-right: 15px;

        .widget-fact-number {
          float: left;
          white-space: nowrap;
          padding: 50% 0;
          height: 0;
          min-width: 130px;

          .widget-fact-number-digits {
            padding-left: 20px;
            transform: translateY(-24px);
          }

          .widget-fact-number-unit {
            transform: translateY(-24px);
            padding-right: 20px;
          }
        }
      }

      .widget-fact-text {
        min-height: 130px;
        text-align: left;
      }
    }
  }
}

.green-bg .widget-fact {
  .widget-fact-wrapper {
    background-color: theme-color("primary");
    border: 1px solid white;

    .widget-fact-number {
      background-color: white;
      color: theme-color("primary");
    }
  }

  .widget-fact-source {
    a {
      color: white;
    }
  }
}


.rich-text {
  > *:last-child {
    clear: both;
  }
  display: flow-root;
}


img.richtext-image, img.copyright-image {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
}


.richtext-image-wrapper {
  position: relative;

  &.left {
    float: left;
    width: 50%;
    margin: 0 15px 15px 0;
  }

  &.right {
    float: right;
    width: 50%;
    margin: 0 0 15px 15px;
  }
}

.widget-image {
  position: relative;
  &.half-width {
    width: 50%;
  }
}

.widget-richtext-content {
  margin: 0 0 30px 0;

  *:first-child {
    margin-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }
}

.widget-gallery {
  &.widget-gallery-columns-2 {
    .col:nth-child(2n+1) {
      clear: left;
    }
  }

  &.widget-gallery-columns-3 {
    .col:nth-child(3n+1) {
      clear: left;
    }
  }

  &.widget-gallery-columns-4 {
    .col:nth-child(4n+1) {
      clear: left;
    }
  }

  &.widget-gallery-columns-6 {
    .col:nth-child(6n+1) {
      clear: left;
    }
  }
}

.widget-embed {
  margin-bottom: 15px;
}

.widget-embed, .widget-richtext-content {
  .responsive-object {
    position: relative;

    iframe, object, embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}


.widget-tabs .widget-tabs-tabnav {
  margin: 0 0 30px 0;

  .nav-tabs {
    border-width: 0;
    background-color: theme-color-level("primary", 0);

    > li {
      margin-bottom: 0;

      a {
        display: block;
        padding: 0.3em 0.5em;
        border-radius: 0;
        font-size: 1rem;
        text-decoration: none;
        background-color: theme-color("primary");
        border: 1px solid theme-color("primary");
        border-bottom: none;
        color: white;

        &.active {
          background-color: white;
          color: theme-color("primary");
        }

      }

    }

  }

  .tab-content {
    background-color: white;
    border: 1px solid theme-color("primary");
    border-top: none;
    padding: 30px 15px 15px 15px;

    *:first-child {
      margin-top: 0;
    }

    *:last-child {
      margin-bottom: 0;
    }
  }
  .tab-pane {
    display: flow-root;
  }
}

.green-bg .widget-tabs .widget-tabs-tabnav {

  .nav-tabs {
    background-color: white;

    > li {

      a {
        background-color: white;
        border: 1px solid white;
        color: theme-color("primary");
        border-bottom: none;

        &.active {
          background-color: theme-color("primary");
          color: white;
        }
      }
    }
  }

  .tab-content {
    background-color: theme-color("primary");
    border: 1px solid white;
    border-top: none;
  }
}


.widget-accordion {
  margin: 0 0 30px 0;

  &.panel-group {
    .panel {
      border-radius: 0;
      border-width: 0;

      &.panel-default {
        .panel-heading {
          background-color: theme-color("primary");
          border-radius: 0;
          color: white;

          padding: 0;

          h4 {
            margin-bottom: 0;
          }

          a {
            text-decoration: none;
            display: block;
            padding: 10px 15px;
            color: white;
          }
        }

        .panel-body {
          border-width: 0;
          background-color: white;
          border: 1px solid theme-color("primary");
          padding-bottom: 15px;
          padding: 0.5em 1em;
        }

        @media (max-width: 767px) {
          .panel-heading {
            text-align: center;
          }

          .panel-body {
            text-align: center;
          }
        }

      }
    }
  }
}

.green-bg .widget-accordion {
  &.panel-group {
    .panel {
      &.panel-default {
        .panel-heading {
          background-color: white;
          color: theme-color("primary");

          a {
            color: theme-color("primary");
          }
        }

        .panel-body {
          background-color: theme-color("primary");
          border: 1px solid white;
        }
      }
    }
  }
}


.widget-monsterbutton {
  display: block;

  a {
    margin: 0 0 15px 0;
    font-weight: bold;
    background-color: white;
    color: theme-color("primary");
    border: 1px solid theme-color("primary");

    &:hover, &:active, &:focus {
      color: theme-color-level("primary", -3);
      border-color: theme-color-level("primary", -3);

      .widget-monsterbutton-icon {
        background-color: theme-color-level("primary", -3);
      }
    }

    display: inline-block;
    position: relative;
    padding: 0 10px 0 60px;

    .widget-monsterbutton-icon {
      position: absolute;
      display: block;
      height: 50px;
      width: 50px;
      top: 0;
      left: 0;
      padding: 7px 13px 0 13px;
      color: white;
      background-color: theme-color("primary");
      font-size: 25px;
    }

    .widget-monsterbutton-text-wrapper {
      display: block;
      padding: 0 0 0 0;
      min-height: 50px;
      line-height: 50px;

      .widget-monsterbutton-text {
        line-height: 1.3em;
        font-size: 14px;
        vertical-align: middle;
        display: inline-block;
      }
    }
  }
}

.green-bg .widget-monsterbutton {
  a {
    background-color: theme-color("primary");
    color: white;
    border: 1px solid white;

    &:hover, &:active, &:focus {
      color: theme-color-level("primary", -10);
      border-color: theme-color-level("primary", -10);

      .widget-monsterbutton-icon {
        background-color: theme-color-level("primary", -10);
      }
    }

    .widget-monsterbutton-icon {
      color: theme-color("primary");
      background-color: white;
    }
  }
}

.widget-monsterbutton-group {
  .widget-monsterbutton {
    display: inline-block;
    padding-right: 15px;

    &:last-child {
      padding-right: 0;
    }
  }
}


.widget-icon-numbers {
  margin-top: 2em;
  margin-bottom: 2em;

  .icon-number-entry {
    text-align: center;

    .icon {
      img {
        max-width: 66%;
        max-height: 80px;
      }
    }

    .stat {
      .number {
        font-size: 3rem;
        font-weight: bold;
      }
    }

    .text {
      font-size: 0.9rem;
    }
  }
}

.green-bg .widget-icon-numbers {
  .stat {
    color: white;
  }
}

.widget-video {
  margin-bottom: 1em;

  .img-container {
    display: block;
    position: relative;

    &:hover {
      box-shadow: 5px 3px 5px rgba(black, 0.1);
    }

    > img {
      width: 100%;
      height: auto;
    }

    .video-overlay {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: rgba(theme-color("primary"), 0.2);
      }

      img {
      }
    }
  }

  .disclaimer {
    font-size: 0.8rem;
  }
}

.green-bg .widget-video {
  a {
    color: white;
  }
}
