.leaflet-bottom {
  z-index: 400;
}

.map-wrapper {
  align-items: stretch;
  height: 100%;
  margin-top: -2em;
  margin-bottom: -2em;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 2fr) minmax(0, 1fr);

  h2 {
    margin-bottom: 0.2em;
  }
  @media (max-width: 1400px) {
    margin-left: -40px;
  }
  @media (max-width: 1120px) {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 991px) {
    margin-left: 0;
  }
}

.map-container {
}

#map {
  position: relative;
  width: 100%;
  height: 100%; /* Fill the entire viewport height */
  min-height: 600px;
}

.panel {
  flex: 1;
  background-color: white;
  padding: 1rem;
  overflow-y: auto;
  color: theme-color("secondary");
}

#info-panel {
  margin-bottom: 20px;
  font-size: 0.95em;
  th,
  td {
    vertical-align: top;
    line-height: 1em;
    padding-bottom: 0.3em;
  }
  td {
    padding-left: 0.3em;
  }
  .logo {
    display: flex;
    justify-content: end;
    img {
      max-height: 100px;
      max-width: 100%;
    }
    margin-bottom: 1em;
  }
  .picture {
    display: flex;
    justify-content: end;
    img {
      max-height: 300px;
      max-width: 100%;
    }
    margin-top: 1em;
    position: relative;
    caption {
      position: absolute;
      bottom: 0.5em;
      right: 0.5em;
      background-color: theme-color("secondary");
      color: white;
      padding: 0.2em 0.3em;
    }
  }
}

#layerControl {
  margin-top: 20px;
}

.filter-column {
  flex: 1;
  background-color: theme-color("primary");
  padding: 1rem;

  label {
    font-weight: bold;
    margin-top: 1em;
    display: block;
  }

  .min-max-filter {
    .filter-row {
      display: flex;
      gap: 0.7em;
    }

    input {
      width: 5em;
      text-align: right;
    }
  }
}

input[type="text"] {
  width: 200px;
}

fieldset {
  all: initial;
  font-family: $font-family-base;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  padding: 0.3em 0.5em 0.5em 0.5em;
  display: block;
  margin-bottom: 0.5em;

  legend {
    all: unset;
    margin-left: 0.1em;
    padding: 0 0.2em;
    font-size: 0.95em;
  }

  label {
    display: inline !important;
    font-weight: normal !important;
    font-size: 0.95em;
  }
}

.category {
  label {
    font-weight: normal;
    margin-top: 0;
    &:hover {
      cursor: pointer;
    }
  }
}

.location-pin {
  color: theme-color("secondary");
  svg {
    width: 140%;
  }

  &.active {
    color: red;
  }
}

.break-container {
  width: 100vw;
  position: relative;
  left: calc(-1 * (100vw - 100%) / 2);
}
