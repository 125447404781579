.page-item-listing {
  margin-bottom: 2em;

  & > div {
    padding-bottom: 2em;
  }

  a {
    display: block;
    text-decoration: none;
    color: theme-color("secondary");
    height: 100%;
    background: white;
    display: flex;

    .image {
      overflow: hidden;
      display: flex;
      margin-bottom: 0;
    }

    &:hover {
      box-shadow: 5px 3px 5px rgba(black, 0.1);
      background-color: theme-color("primary");

      img {
        mix-blend-mode: multiply;
        transform: scale(1.05);
      }
    }


    .page-item {
      border: 1px solid theme-color("primary");
      height: 100%;
      display: flex;
      flex-direction: column;


      .content {
        padding: 1em;
        min-height: 8em;
        background-color: white;
        flex-grow: 1;
      }

      h3 {
        color: theme-color("primary");
      }

      img {
        width: 100%;
        height: auto;
        transition: transform .2s;
      }
      margin-bottom: 2em;
    }
  }
}
