.news-entry {
  position: relative;
  margin-bottom: 1.25em;

  .news-content {
    background-color: theme-color("primary");
    color: white;
    padding: 1.8em 1.5em;
    position: static;

    &:before {
      content: '';
      border-top: 10px solid white;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      top: 0;
    }

    .date {
      font-size: 0.9rem;
      font-weight: bold;
    }

    h3 {
      font-size: 1.2rem;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }

    .text {
      font-size: 0.9rem;

      a {
        color: white;

        &:hover {
          color: theme-color-level("primary", -10);
        }
      }
    }
  }

  .news-image {
    background-color: rgba(black, 0.1);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    min-height: 150px;
    width: 100%;
    position: relative;
  }
}

.hp-news {
  .news-entry {
    .news-image + .news-content {
      //position: relative;
      &:before {
        //border-top-color: transparent;
      }
    }
  }
}

.news-container {
  padding-bottom: 3em;
}
