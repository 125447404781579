$theme-colors: (
  "primary": #009374,
  "secondary": #42261b,
  "investor": #44b7b6,
);
$body-color: #42261b;
$font-family-base: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

@import "node_modules/bootstrap/scss/bootstrap";

@import "navs";
@import "fonts";
@import "footer";
@import "header";
@import "layout";
@import "typography";
@import "news";
@import "block_widgets";
@import "item_page_listing";
@import "map";

// @import "variables";
// @import "mixins";

// @import "base";

// Components
// @import "components/carousel";
