$max-z-index: 20;
$arrow-height: 22px;

header {
  z-index: 1100;
  position: sticky;
  top: 0;
  background-color: theme-color("primary");
  height: 2 * $arrow-height;

  a {
    text-decoration: none;
  }

  > .container {
    display: flex;

    .main-nav-wrapper {
      width: calc(100% - 35px);
    }

    .lang-nav-wrapper {
      width: 35px;
    }
  }

  .navbar {
    padding: 0;
    display: flex;
    justify-content: center;
    @include media-breakpoint-down(md) {
      justify-content: flex-end;
    }

    .navbar-brand {
      height: 2 * $arrow-height;
      line-height: 2 * $arrow-height;
      font-size: 1.4rem;
      background-color: theme-color("secondary");
      padding-right: 0;
      margin-right: $arrow-height;
      position: absolute;
      right: calc(100% - #{$arrow-height});
      color: white;
      font-weight: 900;
      z-index: ($max-z-index + 1);
      padding-right: 0.5em;
      padding-top: 0;
      padding-bottom: 0;
      top: 0;

      @include media-breakpoint-down(md) {
        left: 0;
        right: auto;
      }

      &:before {
        // fill space to the left with brown
        content: "";
        position: absolute;
        top: 0;
        left: -50vw;
        width: 50vw;
        height: $arrow-height * 2;
        background-color: theme-color("secondary");
      }

      &:after {
        // arrow to the right
        content: "";
        border-left: $arrow-height solid theme-color("secondary");
        border-top: $arrow-height solid transparent;
        border-bottom: $arrow-height solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        right: -$arrow-height;
        top: 0;
        bottom: 0;
      }
    }

    .navbar-collapse {
      position: relative;
      transition-duration: 0s !important;

      .navbar-nav {
        background-color: theme-color("primary");

        .nav-item {
          .nav-link {
            color: white;
            padding: 0.5em 0;
            font-size: 1.2rem;
            border-bottom: 1px solid theme-color-level("primary", -8);
          }

          &.active .nav-link {
            color: theme-color-level("primary", -3);
          }
        }

        &.main-menu {
          padding: 2em 2em 4em 2em;

          @include media-breakpoint-down(md) {
            margin-left: -15px;
            width: 100vw;
            position: absolute;
            top: 44px;
            height: 100vh;
            border-top: 1px solid white;
          }

          .dropdown-menu {
            border-radius: 0;
            border: none;
            background-color: theme-color-level("primary", 0);
            margin: 0;

            .dropdown-item {
              padding-right: 0;
              padding-left: 2em;

              &:hover {
                background-color: inherit;
              }

              .nav-link {
                //border-bottom-color: theme-color-level("primary", 0);
                color: theme-color-level("primary", -10);
              }
            }
          }

          @include media-breakpoint-up(lg) {
            padding: 0;

            .nav-item {
              padding-left: $arrow-height;
              position: relative;
              height: 2 * $arrow-height;
              line-height: 2 * $arrow-height;

              .nav-link {
                text-align: center;
                color: white;
                padding: 0 0.2em 0 0.5em;
                font-size: 1rem;
                border: none;
              }

              &.active .nav-link {
                color: white;
              }

              @for $i from 0 to $max-z-index {
                &:nth-child(#{$i + 1}) {
                  z-index: ($max-z-index - $i);
                }
              }

              &:hover {
                background: theme-color-level("primary", -3);

                &:after {
                  border-left-color: theme-color-level("primary", -3);
                }
              }

              &:after {
                // arrow to the right
                content: "";
                border-left: $arrow-height solid theme-color("primary");
                border-top: $arrow-height solid transparent;
                border-bottom: $arrow-height solid transparent;
                height: 0;
                width: 0;
                position: absolute;
                right: -$arrow-height;
                top: 0;
                bottom: 0;
              }
            }

            li.dropdown {
              z-index: 100;

              > .nav-link {
              }

              .dropdown-menu {
                background-color: theme-color("primary");
                margin: 0;
                padding: 4em 1em;
                border: none;
                border-radius: 0;

                .dropdown-item {
                  padding: 0;

                  .nav-link {
                    text-align: left;
                    background-color: inherit;
                    color: white;
                    border-bottom: 1px solid theme-color-level("primary", -8);

                    &:hover {
                      color: theme-color-level("primary", -10);
                    }
                  }

                  &:hover {
                    background-color: inherit;
                  }
                }
              }
            }

            li.dropdown:hover .dropdown-menu {
              display: block;
            }
          }
        }
      }
    }

    .navbar-nav.language-switch {
      padding: 0;
      z-index: 20;

      .nav-item {
        margin: 0;
        padding: 0;

        .nav-link {
          text-transform: uppercase;
          color: white;
          line-height: 1;
          display: block;
          width: 35px;
          background-color: theme-color-level("primary", -3);
          font-size: 1rem;
          padding: 0.88em 0 0.35em 0;
          border: none;
          text-align: center;

          &:hover {
            background-color: theme-color-level("primary", -5);
          }
        }

        &.active {
          display: none;
        }
      }
    }
  }

  .navbar-toggler {
    margin-top: 8px;
    margin-left: 1em;
    justify-self: flex-end;
    align-self: flex-start;
    width: 36px;
    text-align: center;
    padding: 0;
    display: block;
    flex-shrink: 0;

    @include media-breakpoint-up(lg) {
      display: none;
    }

    img.open-menu {
      display: none;
    }

    &:focus {
      outline: none;
    }

    &.collapsed {
      img.close-menu {
        display: none;
      }

      img.open-menu {
        display: block;
      }
    }
  }
}

$arrow-height: 18px;

.sub-nav {
  align-items: flex-end;

  .nav-item {
    margin-bottom: 0.5em;
    background-color: theme-color("secondary");
    height: 2 * $arrow-height;
    position: relative;
    display: flex;
    align-items: center;
    text-align: right;

    &:before {
      // arrow to the left
      content: "";
      border-right: $arrow-height solid theme-color("secondary");
      border-top: $arrow-height solid transparent;
      border-bottom: $arrow-height solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      left: -$arrow-height;
      top: 0;
      bottom: 0;
    }

    &.active {
      background-color: theme-color("primary");

      &:before {
        border-right-color: theme-color-level("primary");
      }

      &:hover {
        cursor: default;
      }
    }

    &:hover:not(.active) {
      background-color: theme-color-level("primary", -3);

      &:before {
        border-right-color: theme-color-level("primary", -3);
      }
    }

    .nav-link {
      color: white;
      display: inline-block;
      line-height: 1;
      padding: 0 1em 0 0.5em;
      text-decoration: none;
      text-align: right;
    }
  }
}

.breadcrumb {
  font-size: 0.8rem;
  background-color: white;
  padding-left: 0;
  margin-top: 01em;
  margin-bottom: 0;

  .breadcrumb-item {
    color: theme-color("secondary");

    & + .breadcrumb-item::before {
      color: theme-color("secondary");
      content: ".";
    }
  }
}
