.hero-area {
  padding: 0;
  border-bottom: 10px solid theme-color("primary");
  height: 400px;
  box-sizing: content-box;
  position: relative;

  .bg-image-container {
    height: 100%;
    overflow: hidden;
    position: relative;

    .bg-image {
      min-height: 400px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

  .carousel {
    height: 100%;
    overflow: hidden;

    .slide {
      position: relative;
    }
  }

  .header-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;

    .container {
      height: 100%;
    }

    .header-box {
      position: absolute;
      bottom: 1.2em;
      width: 35%;

      @include media-breakpoint-down(md) {
        width: 100%;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: theme-color("primary");
        mix-blend-mode: multiply;

        @include media-breakpoint-down(md) {
          left: -15px;
          width: 100%;
        }
      }

      .box-content {
        border: 1px solid rgba(theme-color-level("primary", -10), 0.7);
        padding: 1.7em 1.7em 2em 1.7em;
        color: white;
        position: relative;
        width: calc(100% + 2px);
        height: 100%;
        margin-left: -1px;

        @include media-breakpoint-down(md) {
          border-left: none;
          border-right: none;
          margin-left: -15px;
        }

        h2 {
          color: white;
          margin: 0;
          line-height: 1.1;
          font-size: 2rem;
        }

        .text {
          margin-top: 1.5em;
          margin-bottom: 1.5em;
        }

        .action {
          text-align: right;

          a.btn {
            display: inline-block;
            border: 1px solid white;
            color: white;
            border-radius: 0;
            text-decoration: none;

            &:hover {
              color: theme-color("primary");
              border-color: theme-color-level("primary", -10);
              background-color: rgba(white, 0.8);
            }
          }
        }
      }

      .slider-nav {
        position: relative;
        display: flex;

        a {
          display: inline-block;
          color: white;
          width: calc(100% / 6);
          text-align: center;
          height: 2.5em;
          line-height: 2.5em;
          text-decoration: none;

          img {
            padding: 0.3em;
            height: 100%;
            display: inline-block;
          }

          &.active,
          &:hover {
            background-color: rgba(white, 0.5);
            text-decoration: none;
          }

          margin: 0;
          padding: 0;
          outline: 0;
        }

        a + a {
          border-left: 1px solid rgba(white, 0.7);
        }
      }
    }
  }
}

.header-content.logo-container {
  height: auto;
  pointer-events: none;

  .container {
    overflow: visible;
    height: auto;

    .container-content {
      position: relative;

      .logo-wrapper {
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;

        .logo {
          height: 100%; // overriden by js
          float: right;
          position: relative;
          z-index: 1200;
          pointer-events: none;

          > a {
            display: block;
            background-color: white;
            position: sticky;
            top: 44px;
            pointer-events: all;
          }

          img {
            padding: 1.1em 1.55em;
            width: 170px;
            box-sizing: content-box;

            @include media-breakpoint-down(sm) {
              padding: 0.6em 0.9em;
              width: 120px;
            }
          }
        }
      }
    }
  }
}
