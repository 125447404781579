@font-face {
  font-family: 'Inter';
  src: url('../../../static/fonts/Inter-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../../../static/fonts/Inter-ExtraBold.ttf') format('ttf');
  font-weight: 900;
  font-style: normal;
}
