html {
  overflow-x: hidden;
}

// give room for logo
@include media-breakpoint-up(lg) {
  @media (max-width: 1400px) {
    body,
    header,
    .header-content,
    .sub-header,
    footer .bar,
    .sub-section {
      > .container {
        padding-left: 130px;
        padding-right: 50px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .container {
    max-width: 100%;
  }
  .container-fluid > .container {
    padding-left: 0;
    padding-right: 0;
  }
}

.content.container {
  min-height: 70vh;
}

.image {
  margin: 0 0 30px 0;
  display: block;
  width: 100%;
  height: auto;
}

section {
  &.main-section {
    padding-bottom: 3.5em;

    h1 {
      font-size: 2.25rem;
    }
  }

  &.sub-header {
    position: sticky;
    top: 44px;
    background-color: white;
    z-index: 1100;
  }

  &.sub-section {
    position: relative;

    > .container:before {
      content: "";
      border-top: 10px solid theme-color("primary");
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      top: 0;
    }

    &.green-bg {
      background-color: theme-color("primary");
      color: white;

      > .container:before {
        border-top-color: white;
      }

      h2 {
        color: white;
      }

      a {
        color: lighten(theme-color("secondary"), 65%);
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    padding-top: 3.5em;
    padding-bottom: 3.5em;

    h2 {
      margin-bottom: 1em;
    }
  }
}
