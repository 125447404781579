h1, h2 {
  color: theme-color("primary");
  margin-bottom: 0.5em;
}

h1 {
  font-size: 2.25rem;
}

a {
  text-decoration: underline;

  &:hover {
    color: theme-color-level("primary", -3);
    text-decoration: none;
  }
}

.copyright {
  position: absolute;
  bottom: 0;
  right: 0;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 0.1em 0.3em;
  font-size: 0.75em;
  font-style: italic;
}
