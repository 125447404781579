footer {

  .bar {
    border-top: 1px solid theme-color("primary");
    border-bottom: 1px solid theme-color("primary");
    width: 100%;

    a {
      text-decoration: none;
    }

    .navbar {
      font-size: 18px;
      padding-left: 0;

      .navbar-nav {
        width: 100%;
        flex-wrap: wrap;
        margin-left: -0.5em;

        .nav-link {
          padding-right: 2.5em;
        }

      }
    }
  }

  .footer-sinnwerkstatt {
    margin-top: 2em;
    font-size: 0.7rem;
  }
}
